import Header from "components/Header/Header";
import Top from "components/Top/Top";
import Main from "components/Main/Main";
import TabBar from "components/TabBar/TabBar";

const Home = () => {
  const downLoadApp = () => {
    if ((window as any).chromePrompt) {
      (window as any).chromePrompt.prompt();
      return;
    }
    window.open("https://www.playing.io/");
  };

  return (
    <div>
      <Header downLoadApp={downLoadApp} />
      <Top downLoadApp={downLoadApp} />
      <Main downLoadApp={downLoadApp} />
      <TabBar downLoadApp={downLoadApp} />
    </div>
  );
};

export default Home;
